import { h } from 'preact';
import { route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Page from '../routes/page';

const App = () => {
	const handleRoute = (e) => {
		if(e.url == "/"){
			route('/page/1', true);
		}
	}
	return (
		<div id="app">
			<Header />
			<Router onChange={handleRoute}>
				<Page path={`/page/:page`} />
			</Router>
		</div>
	)
}

export default App;
